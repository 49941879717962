/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import plonevoltoSlate from '@plone/volto-slate';
import voltoDemo from 'volto-demo';

const addonsInfo = [{"name":"@plone/volto-slate","version":"18.0.4","isPublishedPackage":true,"isRegisteredAddon":false,"modulePath":"/app/core/packages/volto-slate/src","packageJson":"/app/core/packages/volto-slate/package.json","basePath":"/app/core/packages/volto-slate","tsConfigPaths":null,"addons":[]},{"name":"volto-demo","version":"1.0.0-alpha.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/app/packages/volto-demo/src","packageJson":"/app/packages/volto-demo/package.json","basePath":"/app/packages/volto-demo","tsConfigPaths":[null,{"@plone/volto/*":["../../core/packages/volto/src/*"],"volto-demo/*":["./src/*"]}],"addons":[]}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}


const projectConfig = (config) => {
  return projectConfigLoader && typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [plonevoltoSlate, voltoDemo];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
